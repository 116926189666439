<template>
  <div class="errPage-container">
    <el-button icon="arrow-left" class="pan-back-btn" @click="back">
      返回
    </el-button>
    <el-row>
      <el-col :span="12">
        <h1 class="text-jumbo text-ginormous">403错误!</h1>
        <h2>您没有访问权限！</h2>
        <h6>对不起，您没有访问权限，请不要进行非法操作！您可以返回主页面</h6>
        <ul class="list-unstyled">
          <li class="link-type">
            <router-link to="/"> 回首页</router-link>
          </li>
        </ul>
      </el-col>
      <el-col :span="12">
        <img :src="errGif"
             width="313"
             height="428"
             alt="Girl has dropped her ice cream."/>
      </el-col>
    </el-row>
  </div>
</template>

// js
<script>
import errGif from "../../assets/images/403_images/403.gif";

export default {
  // 组件名
  name: "page_403",
  data() {
    return {
      errGif: errGif + "?" + +new Date(),
    };
  },
  created() {
  },
  methods: {
    back() {
      if (this.$route.query.noGoBack) {
        this.$router.push({
          path: "/"
        });
      } else if (this.$route.query.msg == '非法入侵') {
        this.$router.go(-3);
      } else {
        this.$router.go(-1);
      }
    },
  },
};
</script>

// 样式，scoped属性，当前组件页生效
<style lang="less" scoped>
.errPage-container {
  width: 800px;
  max-width: 100%;
  margin: 100px auto;
  transform: translate(-50%, -50%);
  position: absolute;
  top: 40%;
  left: 50%;

  .pan-back-btn {
    background: #008489;
    color: #fff;
    border: none !important;
  }

  .pan-gif {
    margin: 0 auto;
    display: block;
  }

  .pan-img {
    display: block;
    margin: 0 auto;
    width: 100%;
  }

  .text-jumbo {
    font-size: 60px;
    font-weight: 700;
    // color: #484848;
    color: #ff0000;
  }

  .list-unstyled {
    font-size: 14px;

    li {
      padding-bottom: 5px;
    }

    a {
      color: #00d1c1;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  h2,
  h6 {
    color: #808080;
  }
}
</style>
